<template>
  <div class="OpenNumberNew" :class="{'OpenNumberNew_cx4':gameId == 52}">
    <p class="font-small text-center">{{gameName}}</p>
    <p class="text-center font-big bold">上海市开奖公告</p>
    <p class="text-center font-small">第{{info.issueName}}期</p>
    <p class="font-small">本期销售额：{{ info.totalMoney || '0' }} 元</p>
    <p class="font-small">开奖日期：{{ openBonusTime(info.prizeDate,'YYYY年MM月DD日', false) }}</p>
    <div class="balls font-small">
      <span>开奖号码：</span>
      <ball
        class="ball"
        :isChoose="true"
        :small="true"
        v-for="(ball,index) in info.redBall"
        :key="index"
        :value="ball"
      />
    </div>
  </div>
</template>

<script>
import ball from "../../../components/Ball.vue";
import { openBonusTime } from "../../../utils/util/index";
import { computed } from "vue";
export default {
  components: { ball },
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    gameId: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const gameName = computed(() => {
      let name = "";
      switch (props.gameId) {
        case 51:
          name = "中国福利彩票“3D”";
          break;
        case 52:
          name = "中国福利彩票“ 天天彩 ( 选4 ) ”";
          break;
      }
      return name;
    });
    return {
      openBonusTime,
      gameName
    };
  }
};
</script>

<style lang="scss" scoped>
.OpenNumberNew {
  .balls {
    display: flex;
    align-items: center;
    .ball {
      margin-left: 5px;
    }
  }
}
</style>