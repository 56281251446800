<template>
    <div @click="ballClick" :class="ballClass">
        <span>{{value}}</span>
    </div>
</template>
<script>
import { computed } from '@vue/runtime-core'
export default {
    name: 'ball',
    props: {
        value: {
            type: String,
            default: '0',
        },
        color: {
            type: String,
            default: 'red'
        },
        isChoose: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false,
        }
    },
    setup (prop, context) {
        const ballClass = computed(() => {
            // const _class = prop.color + ' ' + (prop.isChoose ?  'choosed_' + prop.color : '') + ' ' + prop.small ? 'small_ball' : 'ball'
            const _class = ' ' + `${prop.isChoose ? 'choosed_'+prop.color : prop.color} ${prop.small ? 'small_ball' : 'ball'}`
            return _class
        })
        function ballClick() {
            context.emit('ballClick')
        }
        return {
            ballClass,
            ballClick
        }
    }
}
</script>
<style lang='scss' scoped>
.ball {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: $font-big;
    border: solid 1px;
    margin: 1px 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
}
.small_ball {
    width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    font-size: $font-less;
    display: inline-block;
}
.red {
    border: solid 1px rgba($theme-dark, 0.3);
    border-radius: 50%;
    color: $theme-light;
    background: #fff;
}
.choosed_red {
    background: $theme-linear-color;
    color: #fff;
    border-radius: 50%;
    border-color: $theme-light;
}
.blue {
    border: solid 1px rgba(#4da6f2, 0.3);
    border-radius: 50%;
    color: #4da6f2;
    background: #fff;
}
.choosed_blue {
    background: #4da6f2;
    border-color: #4da6f2;
    color: #fff;
    border-radius: 50%;
}
</style>