<template>
    <div 
        class='open_number' 
        :style="getBg(balls.url)" 
        :class="{'padding60': showIcon, 'small_open_number': small, 'more_icon': false}">
        <div class="open_number_info">
            <p>{{balls.name}} <span>第{{balls.issueName}}期 <small>{{openBonusTime(balls.prizeDate)}}</small></span></p>
        </div>
        <div @click="handleEvent('ballClick')" class="open_number_number">
            <div 
                class="red_ball" 
                v-for="(redball, index) in balls.redBall"
                :key="index+redball">
                <ball :isChoose="isChoose" :small="small" :value="redball"/>
            </div>
            <div 
            class="blue_ball" 
            v-for="(blueball, index) in balls.blueBall"
            :key="blueball+index">
                <ball :isChoose="isChoose"  :small="small" color="blue" :value="blueball"/>
            </div>
            <div class="sum_num" v-if="balls.redBall?.length == 3">
                和值：{{getCount(balls)}}
            </div>
            <!-- 操作事件 -->
            <div v-show="showMoreIcon" class="handle_content">
                <span v-for="(button, buttonIndex) in buttons" 
                    @click.stop="handleEvent(button.eventName)"
                    :key="buttonIndex">{{button.label}}</span>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { openBonusTime } from '../utils/util'
import Ball from './Ball.vue'
export default {
    name: '',
    components: {Ball},
    props: {
        balls: {
          type: Object,
          default: () => {
              return {
                  name: '',
                  issueName: '',
                //   redBall:  [1, 2, 3, 4],
                //   blueBall: [6, 7, 8],
                  redBall:  [],
                  blueBall: [],
              }
          }  
        },
        buttons: {
            type: Array,
            default: () => {
                return [
                    {label: '往期', eventName: 'detail'},
                    // {label: '分布图', eventName: 'chart'}
                ]
            }
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showMoreIcon: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: false
        },
        isChoose: {
            type: Boolean,
            default: false
        },
    },
    setup(props, context) {
        const state= reactive({
        })
        function getBg(url) {
            return props.showIcon ?
            {
                backgroundImage: `url(${url})`,
            }
            :
            {}
        }
        function handleEvent(eventName) {
            context.emit(eventName)
        }
        function getCount(balls) {
            const {redBall} = balls
            let count = 0
            redBall?.forEach(ele => {
                count = count+Number(ele)
            })
            return count
        }
        return {
            ...toRefs(state),
            getBg,
            openBonusTime,
            handleEvent,
            getCount
        }
    },
}
</script>
<style lang='scss' scoped>
.padding60 {
    padding-left: 55px !important;
}
.small_open_number {
    font-size: $font-small !important;
    padding: 6px 0 0 0px !important;
    .open_number_number {
        padding: 6px 0 !important;
    }
}
.open_number_number :deep .ball {
        height: 28px;
        width: 28px;
    }
.red_ball, .blue_ball {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.open_number {
    background: #fff;
    padding: 10px 0 0px 10px;
    position: relative;
    background-position: 0px 15px;
    background-repeat: no-repeat;
    background-size: 50px;
    // border-top: solid 1px $line-gray;
    // border-bottom: solid 1px $line-gray;
    .sum_num {
        line-height: 31px;
        padding-left: 15px;
        color: #000;
    }
    &_info {
        font-size: $font-middle;
        padding-bottom: 7px;
        span {
            color: $gray-dark;
            padding-left: 3px;
        }
        small {
            color: $gray-middle;
            padding-left: 3px;
        } 
    }
    &_number {
        display: flex;
        padding:5px 0 10px 0;
        flex-wrap: wrap;
        position: relative;
        color: $gray-middle;
        font-size: $font-small;
        span {
            padding: 3px 5px;
            &:nth-child(2) {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    height: 10px;
                    background: $gray-middle;
                    width: 1px;
                }
            }
        }
        .handle_content {
            position: absolute;
            right: 0;
            top: calc( 50% - 2px);
            transform: translateY(-50%);
            span{
                padding: 20px 0px;
                &:first-child {
                    padding-right: 10px;
                }
                &:last-child {
                    padding-left: 10px;
                }
            }
        }
    }
}
.more_icon {
        background: #fff url('../assets/imgs/arrow_right_gray.png') right center no-repeat;
        background-size: 9px;
        padding-right: $margin-big;
        padding-right: 0;
        border-right: 10px solid #fff;
    }
</style>