<template>
  <div>
    <!-- 走势图 -->
    <div v-if="false" class="trend_chart">
      <img @click="goChartHome" src="../../assets/imgs/trend_chart_icon.png" />
    </div>
    <ul class="code_list">
      <NoData v-if="infos?.length < 1 && !loading" />
      <div v-else>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item v-for="(item, index) in infos" :key="index" :name="index+''">
            <template #title>
              <OpenNumberNew
                :gameId="Number(gameId)"
                :info="item"
                v-if="gameId == 51 || gameId == 52"
              />
              <open-number
                :buttons="buttons"
                :showIcon="false"
                :showMoreIcon="true"
                :isChoose="true"
                :balls="item"
                :gameId="gameId"
                v-else
              />
            </template>
            <template v-if="item.detailPrizes">
              <bonusItemNew
                :detailTitle="detailTitle"
                :tabelTh="tabelTh"
                :item="item"
                :getValueInfo="getValueInfo"
                v-if="gameId == 51 || gameId == 52"
                :gameId="gameId"
              />
              <!--  -->
              <div class="detail" v-else>
                <ul class="detail_title">
                  <template v-for="(title, titleIndex) in detailTitle" :key="titleIndex">
                    <li v-if="title.label != '组选三特别奖奖池' || item.showBonusPool1">
                      <p class="red">{{moneyFormat(item[title.prop])}}</p>
                      <p>{{title.label}}</p>
                    </li>
                  </template>
                </ul>
                <table>
                  <tr>
                    <th v-for="(thValue, thIndex) in tabelTh" :key="thIndex">{{thValue}}</th>
                  </tr>
                  <tr v-for="(row, rowIndex) in getValueInfo(item.detailPrizes)" :key="rowIndex">
                    <td>{{row[0]}}</td>
                    <td>
                      <p>{{row[1]}}</p>
                      <p v-if="gameId == 11 &&  item.pool2  && row[3] == 7">下期派奖金额：</p>
                      <p v-if="gameId == 11 && item.pool4  && row[3] == 8">下期派奖金额：</p>
                    </td>
                    <td class="red">
                      <p>{{moneyFormat(row[2])}}</p>
                      <p
                        v-if="gameId == 11 && item.pool2  && row[3] == 7"
                      >{{moneyFormat(item.pool2)}}</p>
                      <p
                        v-if="gameId == 11 && item.pool4 && row[3] == 8"
                      >{{moneyFormat(item.pool4)}}</p>
                    </td>
                    <!-- 3D显示 每个玩法的中奖金额-->
                    <td class="red" v-if="gameId == 51">
                      <p>{{moneyFormat(row[4])}}</p>
                    </td>
                  </tr>
                </table>
                <div class="detail_cutOffTime" v-if="gameId == 51">
                  <span>本期兑奖截止日为：{{ item.cutOffTime }}，逾期未兑奖视为弃奖，弃奖奖金纳入彩票公益金。</span>
                </div>
              </div>
            </template>
            <div v-else>
              <p class="no_data_text">暂无数据</p>
            </div>
          </van-collapse-item>
        </van-collapse>
        <p v-show="!loading" class="default_tip">已显示近半年全部开奖公告</p>
      </div>
    </ul>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { wincodeList } from "../../utils/api";
import {
  getQueryStr,
  jumpTo,
  openBonusTime,
  valueMap,
  moneyFormat
} from "../../utils/util";
import { gameIDMap } from "../../common-data";
import OpenNumber from "../../components/OpenNumber.vue";
import bonusItemNew from "./components/bonusItemNew.vue";
import OpenNumberNew from "./components/OpenNumberNew.vue";

export default {
  name: "",
  components: { OpenNumber, OpenNumberNew, bonusItemNew },
  setup() {
    const state = reactive({
      infos: [],
      buttons: [],
      activeName: "0",
      detailTitle: [
        { label: "本期销售额", value: "", prop: "totalMoney" },
        { label: "奖池金额", value: "", prop: "bonusPool" }
      ],
      tabelTh: ["奖等", "中奖注数(注)", "单注中奖金额(元)"],
      hasDetail: false,
      loading: true
    });
    const gameId = parseInt(getQueryStr("gameId")) || "11";
    const gameInfo = gameIDMap.get(gameId);

    // 3D增加组3奖池   3D表格显示中奖金额
    if (gameId == 51) {
      state.detailTitle.push({
        label: "组选三特别奖奖池",
        value: "",
        prop: "pool1"
      });
      state.detailTitle.shift();
      state.detailTitle.unshift({
        label: "中奖总金额",
        value: "",
        prop: "totalPrizeMoney"
      });
      // state.tabelTh.push("中奖金额(元)");
    }
    // const qcId = gameInfo.qcId
    async function loadNormalData() {
      try {
        const result = await wincodeList(gameId);
        if (result.code == 0) {
          state.infos = result.data;
          initData(state.infos);
        }
      } catch (e) {
        console.error(e);
      }
    }

    function getValueInfo(data) {
      const arr = data?.split("#");
      let valueArr = [];
      arr.forEach(ele => {
        const _arr = ele.split(",");
        let trArr = [_arr[3], _arr[1], _arr[2], _arr[0]];
        if (gameId == 51) {
          trArr.push(_arr[4]);
        }
        valueArr.push(trArr);
      });
      return valueArr;
    }
    // async function loadQcData() {
    //     try {
    //         const params = {
    //             gameId: qcId,
    //             page: 1,
    //             pageSize: 100
    //         }
    //         const result = await getOpenBonusHistory(params)
    //         if (result.code == 0) {
    //             state.infos = result.data?.records
    //             initData(state.infos)
    //         }
    //     } catch(e) {
    //         console.error(e)
    //     }
    // }
    function initData(data) {
      const name = gameIDMap.get(gameId)?.name;
      data.forEach(
        ele => {
          ele.time = openBonusTime(ele.endTime);
          ele.name = name;
          const code = ele.winningCode.split("#");
          ele.redBall = code[0].split(",");
          ele.blueBall = code[1] && code[1].split(",");
          ele.winTotalAmount = 0;
          ele.levelList.forEach(item => {
            ele.winTotalAmount += item.bonusNum * item.bonusLevelPrice;
          });

          if (gameId == 61) {
            ele.blueBall = ele.blueBall.map(ele => valueMap.get(ele) || ele);
          }
          //3D
          if (gameId == 51) {
            // 3D不显示奖池  显示组三奖池 计算兑奖期限
            ele.D3WinTotalAmount = 0;
            // ele.cutOffTime = getDateAfterNDay(ele.prizeDate, 60);
            // ele.bonusPool = "--";
            if (ele.pool1 && ele.pool1 > 0) {
              ele.showBonusPool1 = true;
            }
            // 3D加奖处理  单选 组三 组六
            if (ele.detailPrizes) {
              const arr = ele.detailPrizes.split("#");
              const valueArr = arr.map(item => {
                const _arr = item.split(",");
                return _arr;
              });
              // valueArr.forEach((item, index) => {
              //   if (item[3] == "单选") {
              //     item[2] = item[2] - 160;
              //     if (item[1] > 0) {
              //       valueArr.splice(index + 1, 0, [
              //         index + 1,
              //         item[1],
              //         160,
              //         "单选派奖"
              //       ]);
              //     }
              //   } else if (item[3] == "组选3") {
              //     if (item[1] > 0) {
              //       valueArr.splice(index + 1, 0, [
              //         index + 1,
              //         item[1],
              //         item[2] - 346,
              //         "组选3派奖"
              //       ]);
              //     }
              //     item[2] = 346;
              //   } else if (item[3] == "组选6") {
              //     item[2] = item[2] - 27;
              //     if (item[1] > 0) {
              //       valueArr.splice(index + 1, 0, [
              //         index + 1,
              //         item[1],
              //         27,
              //         "组选6派奖"
              //       ]);
              //     }
              //   }
              // });
              ele.detailPrizes = "";
              valueArr.forEach((item, index) => {
                valueArr[index].push(item[1] * item[2]);
                ele.D3WinTotalAmount += item[4];
                ele.detailPrizes += `${valueArr[index].join(",")}${
                  index == valueArr.length - 1 ? "" : "#"
                }`;
              });
            }
          }
        }
        // if (qcId) {
        //     if (qcId == 1 || qcId == 4) { // 双色球和七乐彩，最后一位为篮球
        //         const l = ele.winBaseCodeList?.length
        //         ele.redBall = ele.winBaseCodeList.slice(0, l-1)
        //         ele.blueBall = ele.winBaseCodeList.slice(l-1, l)
        //     } else {
        //         ele.redBall = ele.winBaseCodeList
        //     }
        // } else {
        //     const code = ele.winningCode.split('#')
        //     ele.redBall = code[0].split(',')
        //     ele.blueBall = code[1] && code[1].split(',')
        //     if (gameId == 61) {
        //         ele.blueBall = ele.blueBall.map(ele => valueMap.get(ele) || ele)
        //         }
        //     }
        // }
      );
      state.loading = false;
      console.log(data);
    }
    function chooseNum() {
      if (state.inWhiteList) {
        jumpTo(`/choosenumber?gameId=${gameId}`);
      }
    }
    // function initLoadData() {
    //     if (qcId) {
    //         loadQcData()
    //     } else {
    //         loadNormalData()
    //     }
    // }
    // initLoadData()
    loadNormalData();
    function goChartHome() {
      jumpTo("/chartHome");
    }
    return {
      ...toRefs(state),
      gameInfo,
      chooseNum,
      goChartHome,
      // qcId,
      getValueInfo,
      moneyFormat,
      gameId
    };
  }
};
</script>
<style lang='scss' scoped>
.trend_chart {
  position: fixed;
  top: 8px;
  right: 13px;
  z-index: 999;
  width: 23px;
  height: 20px;
  img {
    max-width: 100%;
  }
}
:deep .open_number {
  background: transparent;
  padding: $margin-big 0 0 0;
}
:deep .van-icon {
  // padding-top: 56px;
}
:deep .van-collapse-item {
  margin-bottom: $margin-big;
}
.code_list {
  padding: 6px 0 $margin-big 0;

  .detail {
    color: #000;
    &_title {
      display: flex;
      padding: 5px 0 10px 0;
      li {
        width: 50%;
        text-align: center;
        & > p {
          &:first-child {
            font-size: $font-max;
          }
        }
      }
    }
    &_cutOffTime {
      font-size: $font-small;
      margin-top: $margin-big;
      color: $globalColorRed;
    }
  }
  &_item {
    background: $bg-gray;
    margin-bottom: $margin-big;
    border-radius: $box-radius-small;
    position: relative;
  }
  & > p {
    text-align: center;
    color: $gray-light;
    padding: 5px 0 5px 0;
    font-size: $font-small;
  }
  table {
    border-collapse: collapse;
    color: $gray-dark;
    th {
      font-weight: 500;
      background: $bg-gray;
      color: #000;
    }
    td,
    th {
      padding: 8px 0;
      border: solid $line-gray 1px;
      line-height: 2.3em;
    }
    width: 100%;
    text-align: center;
  }
}
.no_data_text {
  text-align: center;
}
.default_tip {
  text-align: center;
  font-size: $font-small;
  color: $gray-light;
}
::v-deep .van-collapse-item__title {
  display: flex;
  align-items: center;
}
</style>