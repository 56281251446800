<template>
  <div class="detail">
    <ul class="detail_title" v-if="gameId != 52">
      <template v-for="(title, titleIndex) in detailTitle" :key="titleIndex">
        <li v-if="title.label != '组选三特别奖奖池' || item.showBonusPool1">
          <p class="red">{{ moneyFormat(item[title.prop]) }}</p>
          <p>{{title.label}}</p>
        </li>
      </template>
    </ul>
    <table v-if="false" class="cx4Table">
      <tr>
        <td class="cx4Table_firstTd">开奖日期</td>
        <td colspan="4">第{{openBonusTime(item.prizeDate,'YYYY年MM月DD日', false)}}期</td>
      </tr>
      <tr>
        <td>销售期号</td>
        <td colspan="4">第{{item.issueName}}期</td>
      </tr>
      <tr>
        <td>开奖号码</td>
        <td class="cx4Table_ballTd" v-for="(ball, ballIndex) in item.redBall" :key="ballIndex">
          <ball class="ball" :isChoose="true" :small="true" :value="ball" />
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <!-- <template v-if="gameId == 52">
          <th
            :rowspan="item.levelList.length + 2"
            style="background: #fff"
            class="cx4Table1_td"
          >
            <p>天</p>
            <p>天</p>
            <p>彩</p>
            <p class="rotate1">（</p>
            <p>选</p>
            <p>4</p>
            <p class="rotate2">）</p>
          </th>
          <th class="cx4Table1_td" style="background: #fff" :rowspan="item.levelList.length + 1">
            <p>中</p>
            <p>奖</p>
            <p>情</p>
            <p>况</p>
          </th>
        </template>-->
        <th v-for="(thValue, thIndex) in tabelTh" :key="thIndex">{{thValue}}</th>
      </tr>
      <tr v-for="(row, rowIndex) in item.levelList" :key="rowIndex">
        <td>{{ row.levelStr }}</td>
        <td>
          <p>{{ row.bonusNum }}</p>
        </td>
        <td class="red">
          <p>{{ moneyFormat(row.bonusLevelPrice) }}</p>
        </td>
        <!-- 3D显示 每个玩法的中奖金额-->
        <!-- <td class="red">
          <p>{{moneyFormat(row[4])}}</p>
        </td>-->
      </tr>
      <!-- 彩选4显示本期销售额 -->
      <!-- <tr v-if="gameId == 52">
        <td colspan="2">本期销售额</td>
        <td colspan="2" class="red">{{ moneyFormat(item.totalMoney) }}</td>
      </tr>-->
    </table>
    <div class="detail_cutOffTime">
      <span v-if="gameId == 52" class="detail_cutOffTime_ctx">
        <p style="color:#000">
          奖池金额：
          <span class="amount red">{{item.bonusPool}}元</span>
        </p>
        <p>兑奖期限: 自开奖之日起60个自然日内兑奖，最后一天为国家法定节假日或者彩票市场休市的，顺延至节假日后或者彩票市场休市结束后的第一个工作日。</p>
      </span>
      <span
        v-else-if="item.prizeEndDate"
      >本期兑奖截止日为：{{ openBonusTime(item.prizeEndDate,'YYYY年MM月DD日', false) }}，逾期未兑奖视为弃奖，弃奖奖金纳入彩票公益金。</span>
    </div>
  </div>
</template>

<script>
import { moneyFormat, openBonusTime } from "../../../utils/util/index";
import ball from "../../../components/Ball.vue";
export default {
  components: { ball },
  props: ["detailTitle", "tabelTh", "item", "getValueInfo", "gameId"],
  setup(content) {
    console.log(content.item.levelList);
    return {
      moneyFormat,
      openBonusTime
    };
  }
};
</script>

<style lang="scss" scoped>
.detail {
  color: #000;
  &_title {
    display: flex;
    padding: 5px 0 10px 0;
    justify-content: space-around;
    li {
      // width: 50%;
      text-align: center;
      & > p {
        &:first-child {
          font-size: $font-big;
        }
      }
    }
  }
  &_cutOffTime {
    font-size: $font-middle;
    margin-top: $margin-big;
    color: $globalColorRed;
    .detail_cutOffTime_ctx {
      > p {
        padding-bottom: 6px;
      }
    }
  }
}

table {
  border-collapse: collapse;
  color: $gray-dark;
  th {
    font-weight: 500;
    background: $bg-gray;
    color: #000;
  }
  td,
  th {
    padding: 8px 0;
    border: solid $line-gray 1px;
    line-height: 2.3em;
  }
  width: 100%;
  text-align: center;
}
.cx4Table {
  .cx4Table_ballTd {
    > div {
      transform: translateY(5px);
    }
  }
  .cx4Table_firstTd {
    width: 116px;
  }
}
.cx4Table1_td {
  padding-left: 6px;
  padding-right: 6px;
  .rotate1 {
    transform: rotate(90deg);
  }
  .rotate2 {
    transform: rotate(90deg);
  }
}
</style>